/* Form container */
#crypto-resource-form {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
  }
  
  /* Form title */
  #crypto-resource-form h2 {
    text-align: center;
    color: #007bff;
    margin-bottom: 20px;
   
  }
  
  /* Form group styling */
  #crypto-resource-form .form-group {
    margin-bottom: 15px;
  }
  
  #crypto-resource-form .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  #crypto-resource-form .form-group input,
  #crypto-resource-form .form-group textarea,
  #crypto-resource-form .form-group .tox-tinymce {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  #crypto-resource-form .form-group input[type="file"] {
    padding: 5px;
  }
  
  #crypto-resource-form .form-group textarea {
    resize: vertical;
    height: 100px;
  }
  
  /* Button styling */
  #crypto-resource-form .btn {
    display: inline-block;
    width: 100%;
    padding: 10px 15px;
    font-size: 1rem;
    text-align: center;
    text-decoration: none;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  #crypto-resource-form .btn:hover {
    background-color: #0056b3;
  }
  
  /* Additional spacing */
  #crypto-resource-form .mt-3 {
    margin-top: 1rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    #crypto-resource-form {
      padding: 15px;
    }
  
    #crypto-resource-form .btn {
      font-size: 0.9rem;
      padding: 8px 12px;
    }
  }
  
.sidebar {
    width: 250px;
    height: 100vh;
color: #495057;
  }
  
  .sidebar-menu a {
    text-decoration: none;
  }
  

  
  .sidebar-logo h6 {
    font-size: 1.2rem;
  }
  

.add-news-container {
  max-width: 600px;
  margin: 0 auto;
}

form div {
  margin-bottom: 15px;
}

form label {
  display: block;
  margin-bottom: 5px;
}

form input, form textarea, form select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

form button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

form button:hover {
  background-color: #45a049;
}

.message {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

/* Add this to your CSS file or in a style tag */
.header--search-form {
  position: relative;
}

.search-results {
  position: absolute;
  right: 0; /* Align the results to the right */
  max-height: 300px; /* Set a max height for the results */
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  padding: 10px;

  width: 300px; /* Adjust width as needed */
  margin-top: 50px;
  border-radius: 2%;
}

.search-results ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.search-results li {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.search-results li:hover {
  background-color: #f7f7f7;
}

.search-results li img {
  margin-right: 10px;
  border-radius: 5px;
}

.search-results li span {
  font-size: 14px;
  color: #333;
}

.loading-indicator, .error-message {
  text-align: center;
  font-size: 14px;
  color: #888;
}

.sidebar-submenu {
  display: none;
  margin-left: 20px;
}

.sidebar-submenu.active {
  display: block;
}

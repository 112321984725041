/* NewsTable.css */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th, td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
}

th {
    background-color: #f4f4f4;
    font-weight: bold;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}

button {
    padding: 6px 12px;
    background-color: #ff4d4d;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;
}

button:hover {
    background-color: #ff1a1a;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

/* Search input styles */
.search-input {
    padding: 8px;
   margin-right: 10px;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

#addbttn{
    padding: 8px;
    border-radius: 4px;

}


.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 5px;
    padding: 10px 15px;
    cursor: pointer;
}

.pagination button.active {
    background-color: #007bff;
    color: white;
}

.pagination button:disabled {
    cursor: not-allowed;
    background-color: #d3d3d3;
}
